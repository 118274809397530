/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from 'styled-components'
import {
  Heading,
  Flex,
  Text,
  CommunityIcon,
  SwapIcon,
  Box
} from '@pancakeswap/uikit'
import useTokenPrice from 'hooks/useStaticInfo'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import { IconCardData } from '../IconCard'
import ExchangeStatics from './ExchangeStatics';



const items = [
  {
    imgSrc: '/images/pic1.png',
    title: 'Web3 Staking',
    description:
      'Staking created a passive income generation technology. Bonus automatical deposit in your wallet.',
    ellipseColor: '#4347FF',
  },
  {
    imgSrc: '/images/pic2.png',
    title: 'Media Marketing Agency',
    description:
      'Produce all forms of media and content for advertising, Such as TV media & All online type media.',
    ellipseColor: '#D728DA',
  },
  {
    imgSrc: '/images/pic3.png',
    title: 'Marketplace',
    description:
      'GOT Marketplace is a vendor marketplace that collects all types of products wholesale from factory.',
    ellipseColor: '#19477D',
  },
  {
    imgSrc: '/images/pic4.png',
    title: 'Care 360',
    description:
      'Is a telemedicine platform that is easily accessible to patients. Ready to receive timely treatment.',
    ellipseColor: '#4347FF',
  },
  {
    imgSrc: '/images/pic5.png',
    title: 'Casinotech',
    description:
      'Casino platform that is legally registered center for service casino service providers in country.',
    ellipseColor: '#D728DA',
  },
  {
    imgSrc: '/images/pic6.png',
    title: 'Innovation 360',
    description:
      'Dietary products that help in nourishing & process for restoring the body treating drug addicts.',
    ellipseColor: '#19477D',
  },
];




const AdvantagesCard = styled.li`
  border: 1px solid;
  border-image-source: radial-gradient(100% 100% at 50% 0, rgba(112, 88, 255, .58) 0, rgba(183, 171, 255, 0) 39.78%, hsla(0, 0%, 100%, 0) 80.46%, hsla(0, 0%, 100%, .13) 100%);
  background: ${({ theme }) => theme.colors.backgroundAlt2};
  padding: 1rem;
  border-radius: 1rem;
  display: grid;
  grid-auto-rows: auto;
  gap: 1rem;

  @media (min-width: 640px) {
    padding: 1.5rem;
    gap: 2rem;
  }

  @media (min-width: 768px) {
    padding: 2.25rem;
  }
`;


const CopyTextContainer = styled.div`
  position: relative;
  padding: 7px;
  border: 1px solid #ddd;
  border-radius: 10px;
  display: flex;
  width: 60%;
`;

const Input = styled.input`
  padding: 7px;
  font-size: 18px;
  border: none;
  outline: none;
  flex-grow: 1;
  background : transparent;
`;

const Button = styled.button`
  padding: 7px;
  background: #26a6cd;
  color: #fff;
  font-size: 18px;
  border: none;
  outline: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SvgIcon = styled.svg`
  width: 24px;
  height: 24px;
  stroke: currentColor;
  stroke-width: 2;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
`;





const Stats = () => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const { observerRef } = useIntersectionObserver();
  const priceStats = useTokenPrice()


  const UsersCardData: IconCardData = {
    icon: <CommunityIcon color="primary" width="72px" />,
  }

  const TradesCardData: IconCardData = {
    icon: <SwapIcon color="primary" width="72px" />,
  }

  return (
    <>

      <Flex mb="48px" flexWrap="wrap" style={{ textAlign: "center" }} justifyContent="space-around">
        {/* <div className=''>Hereer</div>
        <BannerScrollDown className="banner-scroll-down active">
          <a href="#how-buy" className="section-link">
            <span></span>
            <span></span>
            <span></span>
          </a>
        </BannerScrollDown> */}
        <Box width={["100%", null, "23%"]}>
          <ExchangeStatics
            headingText={`$${new Intl.NumberFormat('en-US', { maximumSignificantDigits: 4 }).format(priceStats.tokenprice)}`}
            bodyText="God Coins Price"
            highlightColor={theme.colors.primary}
            {...UsersCardData}
          />
        </Box>
        <Box width={["100%", null, "23%"]}>
          <ExchangeStatics
            headingText={`$${new Intl.NumberFormat('en-US', { maximumSignificantDigits: 9 }).format(priceStats.Marketcap)}`}
            bodyText="Marketcap"
            highlightColor={theme.colors.primary}
            {...UsersCardData}
          />
        </Box>
        <Box width={["100%", null, "23%"]}>
          <ExchangeStatics
            headingText={`${new Intl.NumberFormat('en-US', { maximumSignificantDigits: 9 }).format(Number(priceStats.totalTx.toString()))}`}
            bodyText="Total Transactions"
            highlightColor={theme.colors.primary}
            {...UsersCardData}
          />
        </Box>
        <Box width={["100%", null, "23%"]}>
          <ExchangeStatics
            headingText={`${new Intl.NumberFormat('en-US', { maximumSignificantDigits: 9 }).format(Number(priceStats.totalPair.toString()))}`}
            bodyText="Total Trading Pairs"
            highlightColor={theme.colors.primary}
            {...UsersCardData}
          />
        </Box>

      </Flex>

      <Flex mt="135px" mb="25px" justifyContent="center" alignItems="center" flexDirection="column" ref={observerRef}>
        <Heading mb="10px" color='primary' textAlign="center" scale="xl">
          Advantages
        </Heading>
        <Text textAlign="center" color='textSubtle'>
          Empowering traders and stakers with unparalleled opportunities.
        </Text>
      </Flex>

      <ul className="grid sm:grid-cols-2 md:sm:grid-cols-3 auto-rows-min gap-3 sm:gap-4 md:gap-6 max-w-[1200px] mx-auto mt-3 sm:mt-8">
        <AdvantagesCard>
          <div>
            <Text className="text-subtitle1 sm:text-headline3 text-t-primary font-bold text-center" fontSize="20px">Swap tokens</Text>
            <Text color='textSubtle' className="mt-2 sm:mt-3 text-t-third text-body2 sm:text-body1">Trade any Binance chain (BEP-20) tokens on the platform with ease & fast, Low fees and secure.</Text>
          </div>
          <div className="flex md:items-end justify-center">
            <img
              alt="free"
              loading="lazy"
              width="166"
              height="200"
              decoding="async"
              data-nimg="1"
              className="w-auto h-[138px] sm:h-auto"
              src="/images/swap.png"
              style={{ "color": "transparent" }}
            />
          </div>
        </AdvantagesCard>
        <AdvantagesCard>
          <div>
            <Text className="text-subtitle1 sm:text-headline3 text-t-primary font-bold text-center" fontSize="20px">Liquidity Pools</Text>
            <Text color='textSubtle' className="mt-2 sm:mt-3 text-t-third text-body2 sm:text-body1">After Connect.Your manage liquidity pools such as add liquidity or withdraw easily & securely.</Text>
          </div>
          <div className="flex md:items-end justify-center">
            <img alt="free" loading="lazy" width="167" height="200" decoding="async" data-nimg="1" className="w-auto h-[130px] sm:h-auto" src="/images/pools.png" style={{ "color": "transparent" }} />
          </div>
        </AdvantagesCard>
        <AdvantagesCard>
          <div>
            <Text className="text-subtitle1 sm:text-headline3 text-t-primary font-bold text-center" fontSize="20px">Earn Rewards</Text>
            <Text color='textSubtle' className="mt-2 sm:mt-3 text-t-third text-body2 sm:text-body1">Create passive income. It’s time to enjoy your life and let your money work for you every day.</Text>
          </div>
          <div className="flex md:items-end justify-center">
            <img alt="free" loading="lazy" width="260" height="205" decoding="async" data-nimg="1" className="w-auto h-[113px] sm:h-auto" src="images/staking.png" style={{ "color": "transparent" }} />
          </div>
        </AdvantagesCard>
      </ul>

      <div className="mt-[5.5rem] sm:mt-32 md:mt-[11.25rem] max-w-[1200px] mx-auto px-4 sm:px-6">

        <Heading mb="10px" color='primary' textAlign="center" scale="xl">
          Wallet integrate
        </Heading>

        <Text textAlign="center" color='textSubtle'>
          We value the safety of everyone&apos;s funds. Supports the use of Binance smart chain wallet.
        </Text>

        <ul className="flex gap-2 sm:gap-4 mt-6 sm:mt-7 md:mt-10 flex-wrap justify-center">
          <li
            className="flex min-w-[calc(50%-0.25rem)] sm:min-w-[calc(33%-2rem)] md:min-w-[calc(25%-3rem)] max-w-[calc(50%-0.25rem)] sm:max-w-[calc(33%-2rem)] md:max-w-[calc(25%-3rem)] h-[77px] sm:min-h-[112px] rounded-lg justify-center items-center border border-solid border-[rgba(255,255,255,0.09)] bg-[rgba(255,255,255,0.02)]"
          >
            <img
              loading="lazy"
              src="/images/binance.png"
              alt="Binance Labs"
              width="162"
              height="40"
              decoding="async"
              className="w-auto h-[29px] sm:h-[40px]"
              sizes="(max-width: 767px) 480px, 800px"

            />
          </li>
          <li
            className="flex min-w-[calc(50%-0.25rem)] sm:min-w-[calc(33%-2rem)] md:min-w-[calc(25%-3rem)] max-w-[calc(50%-0.25rem)] sm:max-w-[calc(33%-2rem)] md:max-w-[calc(25%-3rem)] h-[77px] sm:min-h-[112px] rounded-lg justify-center items-center border border-solid border-[rgba(255,255,255,0.09)] bg-[rgba(255,255,255,0.02)]"
          >
            <img
              loading="lazy"
              src="/images/bitget.png"
              alt="Laughing capital"
              width="162"
              height="42"
              decoding="async"
              className="w-auto h-[33px] sm:h-[42px]"
              sizes="(max-width: 767px) 480px, 800px"

            />
          </li>
          <li
            className="flex min-w-[calc(50%-0.25rem)] sm:min-w-[calc(33%-2rem)] md:min-w-[calc(25%-3rem)] max-w-[calc(50%-0.25rem)] sm:max-w-[calc(33%-2rem)] md:max-w-[calc(25%-3rem)] h-[77px] sm:min-h-[112px] rounded-lg justify-center items-center border border-solid border-[rgba(255,255,255,0.09)] bg-[rgba(255,255,255,0.02)]"
          >
            <img
              loading="lazy"
              src="/images/coinbase.png"
              alt="Kronos"
              width="128"
              height="54"
              decoding="async"
              className="w-auto h-[43px] sm:h-[54px]"
              sizes="(max-width: 767px) 480px, 800px"

            />
          </li>
          <li
            className="flex min-w-[calc(50%-0.25rem)] sm:min-w-[calc(33%-2rem)] md:min-w-[calc(25%-3rem)] max-w-[calc(50%-0.25rem)] sm:max-w-[calc(33%-2rem)] md:max-w-[calc(25%-3rem)] h-[77px] sm:min-h-[112px] rounded-lg justify-center items-center border border-solid border-[rgba(255,255,255,0.09)] bg-[rgba(255,255,255,0.02)]"
          >
            <img
              loading="lazy"
              src="/images/exodus.png"
              alt="Token Pocket"
              width="62"
              height="42"
              decoding="async"
              className="w-auto h-[31px] sm:h-[40px]"
              sizes="(max-width: 767px) 480px, 800px"

            />
          </li>
          <li
            className="flex min-w-[calc(50%-0.25rem)] sm:min-w-[calc(33%-2rem)] md:min-w-[calc(25%-3rem)] max-w-[calc(50%-0.25rem)] sm:max-w-[calc(33%-2rem)] md:max-w-[calc(25%-3rem)] h-[77px] sm:min-h-[112px] rounded-lg justify-center items-center border border-solid border-[rgba(255,255,255,0.09)] bg-[rgba(255,255,255,0.02)]"
          >
            <img
              loading="lazy"
              src="/images/mathwallet.png"
              alt="Lux Capital"
              width="222"
              height="36"
              decoding="async"
              className="w-auto h-[36px] sm:h-[50px]"
              sizes="(max-width: 767px) 480px, 800px"

            />
          </li>
          <li
            className="flex min-w-[calc(50%-0.25rem)] sm:min-w-[calc(33%-2rem)] md:min-w-[calc(25%-3rem)] max-w-[calc(50%-0.25rem)] sm:max-w-[calc(33%-2rem)] md:max-w-[calc(25%-3rem)] h-[77px] sm:min-h-[112px] rounded-lg justify-center items-center border border-solid border-[rgba(255,255,255,0.09)] bg-[rgba(255,255,255,0.02)]"
          >
            <img
              loading="lazy"
              src="/images/metamask.png"
              alt="Safepal"
              width="165"
              height="45"
              decoding="async"
              className="w-auto h-[45px] sm:h-[57px]"
              sizes="(max-width: 767px) 480px, 800px"
            />
          </li>
          <li
            className="flex min-w-[calc(50%-0.25rem)] sm:min-w-[calc(33%-2rem)] md:min-w-[calc(25%-3rem)] max-w-[calc(50%-0.25rem)] sm:max-w-[calc(33%-2rem)] md:max-w-[calc(25%-3rem)] h-[77px] sm:min-h-[112px] rounded-lg justify-center items-center border border-solid border-[rgba(255,255,255,0.09)] bg-[rgba(255,255,255,0.02)]"
          >
            <img
              loading="lazy"
              src="/images/safepall.png"
              alt="3commas"
              width="192"
              height="52"
              decoding="async"
              className="w-auto h-[31px] sm:h-[52px]"
              sizes="(max-width: 767px) 480px, 800px"

            />
          </li>
          <li
            className="flex min-w-[calc(50%-0.25rem)] sm:min-w-[calc(33%-2rem)] md:min-w-[calc(25%-3rem)] max-w-[calc(50%-0.25rem)] sm:max-w-[calc(33%-2rem)] md:max-w-[calc(25%-3rem)] h-[77px] sm:min-h-[112px] rounded-lg justify-center items-center border border-solid border-[rgba(255,255,255,0.09)] bg-[rgba(255,255,255,0.02)]"
          >
            <img
              loading="lazy"
              src="/images/trust-wallet.png"
              alt="3commas"
              width="192"
              height="52"
              decoding="async"
              className="w-auto h-[31px] sm:h-[52px]"
              sizes="(max-width: 767px) 480px, 800px"

            />
          </li>
        </ul>
      </div>


      <Flex mt="135px" mb="25px" justifyContent="center" alignItems="center" flexDirection="column" ref={observerRef}>
        <Heading color='primary' mb="10px" textAlign="center" scale="xl">
          Ecosystem
        </Heading>
      </Flex>


      <ul className="mt-[3.125rem] grid grid-rows-[minmax(86px,1fr)] md:grid-cols-3 gap-7 max-w-[1200px] mx-auto">
        {items.map((item, index) => (
          <li
            key={Math.random()}
            style={{
              border: '1px solid #263B61',
              borderRadius: '20px',
            }}
            className="relative p-6 isolate grid grid-cols-[minmax(0,124px)_1fr] gap-4 md:gap-0 md:grid-cols-1 md:auto-rows-min overflow-hidden md:overflow-visible"
          >
            <div className="relative flex justify-center md:static">
              <svg
                className="max-w-full h-[86px] sm:h-[112px] absolute -z-[5] top-[20px] sm:top-[47px] sm:translate-y-0"
                viewBox="0 0 390 112"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_514_1560)">
                  <g opacity="0.4" filter="url(#filter0_f_514_1560)">
                    <ellipse
                      cx="195.5"
                      cy="0.5"
                      rx="154.5"
                      ry="49.5"
                      fill={item.ellipseColor}
                      fillOpacity="0.42"
                    />
                  </g>
                </g>
                <defs>
                  <filter
                    id="filter0_f_514_1560"
                    x="-19"
                    y="-109"
                    width="429"
                    height="219"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation="30"
                      result="effect1_foregroundBlur_514_1560"
                    />
                  </filter>
                  <clipPath id="clip0_514_1560">
                    <rect width="390" height="112" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <img
                alt={item.title}
                aria-hidden="true"
                loading="lazy"
                width="120"
                height="140"
                decoding="async"
                data-nimg="1"
                className="h-20 w-auto sm:h-[140px]"
                src={item.imgSrc}
              />
            </div>
            <div className="sm:p-8 md:p-0">
              <h3 className="md:text-center text-t-primary text-subtitle2 sm:text-subtitle4 md:text-headline3 md:font-medium md:mt-6" style={{ fontSize: "20px" }}>
                {item.title}
              </h3>
              <Text color='textSubtle' className="text-t-third md:text-center text-body2 sm:text-body1 [text-wrap:_balance] mt-0.5 sm:mt-2 md:mt-3">
                {item.description}
              </Text>
            </div>
          </li>
        ))}
      </ul>
    </>
  )
}

export default Stats