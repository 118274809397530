/* eslint-disable @typescript-eslint/no-unused-vars */
import Home from 'views/Home'

const IndexPage = () => {
  return (
    <Home />
  )
}

export default IndexPage
